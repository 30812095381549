import React, { useState } from 'react';
import '../../styles/style.scss';
import Layout from '../../components/layout';
import Careers from '../../components/sections/careers';
import LicensedIn from '../../components/licensed-in';

export default () => {
  const [currentTab, setCurrentTab] = useState('INDIVIDUAL');
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    currentTab,
    setCurrentTab,
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout activePage='careers' {...props}>
      <Careers {...props} />
      <LicensedIn {...props} />
    </Layout>
  );
};
