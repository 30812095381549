import React from 'react';

export default props => {
  return (
    <div id='careers' className='plan-types-container careers-container'>
      <div style={{ marginTop: '12px' }} className='plan-types-title'>
        AT APOLLO INSURANCE GROUP, WE POSITIVELY IMPACT PEOPLE’S LIVES
      </div>
      <div className='careers-video'>
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/dW6vVOxarU8'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer;  encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen></iframe>
      </div>

      <div className={'careers-first-texts'}>
        <div className={'careers-who-we-are'}>
          <div className='plan-types-title'>WHO WE ARE</div>
          <div className='plan-types-text'>
            We are Apollo Insurance Group. A locally and nationally ranked fastest-growing sales organization that has been in the individual, group, Medicare,
            and life insurance industry since 2010. What makes us different? We are passionate about our mission to positively impact people’s lives; our team,
            our clients and our community. We treat everyone like family, and this has been the key to our growth and success.
          </div>
        </div>
        <div className={'careers-what-we-do'}>
          <div className='plan-types-title'>WHAT WE DO</div>
          <div className='plan-types-text'>
            Apollo Insurance Group helps individuals, families, and businesses with what can be seen as a very overwhelming topic, Health Insurance. Our
            approach is to make it understandable, simple, and affordable. You dream it, we plan it, and together we will achieve it!
          </div>
        </div>
      </div>
      <div className='plan-types-title'>CULTURE</div>

      <div className='careers-culture'>
        <div className='careers-culture-block'>
          <div className='careers-plus'>+</div>
          <div className='careers-label'>Leadership</div>
          <div className='careers-separator'>–</div>
          <div className='plan-types-text'>
            Our mission is to positively impact people’s lives. Every agent we impact allows for another positive impact on a clients’ life. Our unprecedented
            training, premium products, and unmatched culture allow us to reach this everyday. We equip our agents for a sustainable career in the sales
            industry. Uncapped earning potential is provided to all our agents and within 8 years of being with us, partnership is attainable.
          </div>
        </div>
        <div className='careers-culture-block'>
          <div className='careers-plus'>+</div>
          <div className='careers-label'>Excellence</div>
          <div className='careers-separator'>–</div>
          <div className='plan-types-text'>
            We care about our team’s success. Any new hire spends their first 8 weeks in training where they will learn how to be successful in our industry.
            Coaching will be provided on topics like insurance, professionalism, marketing, sales, and how to build their book of business. A list of leads is
            also provided to all our new agents to aid in their success with us. Our office is like a small community, always ready to lend a hand to another.
          </div>
        </div>
        <div className='careers-culture-block'>
          <div className='careers-plus'>+</div>
          <div className='careers-label'>Worth Ethic</div>
          <div className='careers-separator'>–</div>
          <div className='plan-types-text'>
            At Apollo we “Work Hard, Play Hard”. Creating a vibrant, dynamic and positive environment allows for us to stay connected, engaged and fulfilled. We
            know the value of sacrifice in being successful but also how to have fun. See for yourself, whether it’s in the office, through team building, or
            our many company-wide trips and events.
          </div>
        </div>
        <div className='careers-culture-block'>
          <div className='careers-plus'>+</div>
          <div className='careers-label'>Sacrifice</div>
          <div className='careers-separator'>-</div>
          <div className='plan-types-text'>
            We create an impact that matters. As sales consultants we took the time to become knowledgeable to provide you the care, respect, and information
            you need to make the right decision. We enjoy going above and beyond to serve our clients, team members and community. Success for one, is success
            for all.
          </div>
        </div>
        <div className='careers-culture-block'>
          <div className='careers-plus'>+</div>
          <div className='careers-label'>Trustworthness</div>
          <div className='careers-separator'>-</div>
          <div className='plan-types-text'>
            We hold ourselves accountable. Part of being able to be trustworthy is having great communication amongst your teammates and clients. At Apollo, we
            pride ourselves on this. We know that the better we communicate to you the less confusion and understanding. Our consultants know how important it
            is in our industry to be honest from the beginning.
          </div>
        </div>
        <div className='careers-culture-block'>
          <div className='careers-plus'>+</div>
          <div className='careers-label'>Courage</div>
          <div className='careers-separator'>-</div>
          <div className='plan-types-text'>
            At Apollo we care about the professional and personal growth of our team. We push ourselves outside of our comfort zone in order to continue to grow
            and develop. We assess our challenges and turn them into objectives to achieve success. By doing so, we grow not only in our profession, but also as
            individuals.
          </div>
        </div>
      </div>
      <div className='plan-types-title'>CAREERS</div>
      <div className='plan-types-text'>
        It starts with you! Apollo Insurance Group is looking for an individual to fill our sales position role and wants to be a part of our unprecedented
        culture. We want someone who can contribute to our goal of positively impacting people’s lives. As you grow with the company you do have the potential
        to have an equity stake in the company at the end of your 8th year. Associates are paid commission plus bonuses. Income for an associate in their first
        year averages around $54,000+. In year 3, the average agent makes $150,000+.
        <br />
        <br />
        Submit your resume now and start to positively impact people’s lives today.
      </div>

      <a href='mailto: careers@apollo-insurance.com'>
        <div className='become-agent-button'>BECOME AN APOLLO AGENT</div>
      </a>
    </div>
  );
};
